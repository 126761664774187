@import "node_modules/loaders.css/src/loaders";
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
$primary-blue: #0073AC;
nav.navbar {
  margin-bottom: 1rem;
}

iframe, object, embed {
  max-width: 100%;
}
.pinchzoom-wrapper {
  width:100%;
  height:auto;
  position:relative;
  overflow:hidden;
}
img {
  max-width: 100%;
  height: auto;
}
.btn-primary {
  background-color: $primary-blue !important;
  border-color: $primary-blue !important;
}
button:disabled {
  background-color: lightgray !important;
  border-color: lightgray !important;
  color: #f8f9fa !important;
  &.can-answer {
    background-color: $primary-blue !important;
    border-color: $primary-blue !important;
  }
}
.correct-answer-container {
  border-top: 1px solid rgba(0, 0, 0, .125);
  padding-top: 1rem;
  margin-top: 1rem;
}
.question-answer-actions {
  margin-top: 1rem;
}
.toast-container .ngx-toaster {
  width: 25vw;
}
.toast-container .ngx-toastr {
  min-width: 300px !important;
  width: 30vw !important;
}
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  // This works around a Chrome bug that can cause the tab to crash when large amounts of
  // non-English text get wrapped: https://bugs.chromium.org/p/chromium/issues/detail?id=1201444
  white-space: nowrap;

  // Avoid browsers rendering the focus ring in some cases.
  outline: 0;

  // Avoid some cases where the browser will still render the native controls (see #9049).
  -webkit-appearance: none;
  -moz-appearance: none;
}
.question__lead-in {
  margin-bottom: 1rem;
}

.alert--comments-neutral {
  color: #212529;
  background-color: #00000008;
  border-color: #d6e9c6;
  padding: .75rem 1.25rem
}
// Begin conditional NIH styling
nav {
  background-color: rgba(0,0,0,.125);
  &[class*='canvas-account-'] {
    background-color: #343a40;
  }
  &.canvas-account-188, &.canvas-account-189, &.canvas-account-190 {
    background-color: #262262;
  }
}
.qt-layout-constraint {
  width: 90%;
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;

  &.canvas-account-188, &.canvas-account-189, &.canvas-account-190 {
    * {
      font-family: Arial, serif !important;
    }
    .btn-primary {
      background-color: #262262 !important;
      font-weight: bold !important;
    }
    button:disabled {
      background-color: lightgray !important;
      border-color: lightgray !important;
      color: #f8f9fa !important;
      &.can-answer {
        background-color: #262262 !important;
        border-color: #262262 !important;
      }
    }
    .alert-warning {
      background-color: #ffdd7f;
    }
    .alert-success {
      background: #7ac79b;
    }
    .explanation {
      background-color: #5eaee0;
    }
    .alert--comments-neutral {
      background-color: #5eaee0;
    }
  }
  //End conditional NIH Styling
}
// There is a bug in the modal backdrop z-index in the ng-bootstrap library (new versions): https://github.com/ng-bootstrap/ng-bootstrap/issues/2686
ngb-modal-backdrop {
  z-index: 1049 !important;
}
